import React, { useState, useContext, useEffect } from 'react';
import CONFIG from "config";
import { withRouter } from "react-router-dom";
import { Helper } from 'utils';
import { Api } from 'services';
import { AppActions } from 'store';
import { DispatchContext } from 'context';
// import { StateContext, DispatchContext } from 'context';
import './dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faFileExcel } from '@fortawesome/free-solid-svg-icons';

import Chart from "react-google-charts";
// import { useWindowSize } from 'hooks';
import { useSnackbar } from 'notistack';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

const Dashboard = props => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useContext(DispatchContext);
    // const state = useContext(StateContext);
    // const windowSize = useWindowSize();
    const [data, setData] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [ultimosPedidos, setUltimosPedidos] = useState(null);
    const [pathPlanillaVentasDelMes, setPathPlanillaVentasDelMes] = useState('a/reportes/pedidos/');
    const [pathPlanillaClientes, setPathPlanillaClientes] = useState('a/reportes/clientes');
    const [anhoMes, setAnhoMes] = useState(null);

    useEffect(() => {
        dispatch(AppActions.setLoading(false));
        dispatch(AppActions.setActionsDisabled(false));
        document.title = CONFIG.APP_NAME + ' - Dashboard';
        getDashboard();
    }, []);
    const inicializarDatos = (datos) => {
        //chart
        let chDt = [["Fecha", "Num. Pedidos"]];
        if (datos.fechas) {
            for (var i in datos.fechas) {
                if (datos.fechas[i].numPedidos) {
                    chDt.push([moment(i).format('D MMMM'), datos.fechas[i].numPedidos]);
                }
            }
        }
        setChartData(chDt);

        //ultimos pedidos
        let ultPeds = [];
        for (var i in datos.ultimosPedidos) {
            ultPeds.push(datos.ultimosPedidos[i]);
        }
        setUltimosPedidos(ultPeds);

    }
    const getDashboard = () => {
        dispatch(AppActions.setLoading(true));
        dispatch(AppActions.setActionsDisabled(true));
        var options = {
            url: Api.route('a/dashboard'),
            method: 'GET'
        };
        Api.requestAxios(options, response => {
            Helper.log('getDashboard response', response);
            if (response.data.error) {
                enqueueSnackbar(response.data.msg, { variant: 'error' });
            } else {
                setData(response.data.datos);
                inicializarDatos(response.data.datos);
            }
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
        }, error => {
            Helper.log('error getDashboard', error);
            dispatch(AppActions.setLoading(false));
            dispatch(AppActions.setActionsDisabled(false));
            enqueueSnackbar('Error al traer datos', { variant: 'error' });
        });
    }
    const getEstado = (ped) => {
        let status = {
            estado: 'Pendiente',
            color: '#2067FA'
        };
        if (ped.recibido) {
            status = {
                estado: 'Pendiente',
                color: '#2067FA'
            };
        }
        if (ped.procesando) {
            status = {
                estado: 'Procesando',
                color: '#666D92'
            };
        }
        if (ped.listo) {
            status = {
                estado: 'Listo',
                color: '#00C58D'
            };
        }
        if (ped.cancelado) {
            status = {
                estado: 'Cancelado',
                color: '#FC5C63'
            };
        }
        return status;
    }
    const getNombreCompleto = (user) => {
        let nombre = '--';
        if (user) {
            if (user.nombre) {
                nombre = user.nombre;
            }
            if (user.nombre && user.apellido) {
                nombre = user.nombre + ' ' + user.apellido;
            }
        }
        return nombre;
    }
    const onChangeAnhoMes = (date) => {
        setAnhoMes(date);
    };
    return (
        <div>
            {data ? <div>
                <div className="w3-row">
                    <div className="w3-col l3 m6 s12 w3-padding bs-bbox">
                        <div className="w3-card w3-padding w3-white w3-round-large">
                            <h3>Pedidos</h3>
                            <h1 className="w3-text-theme">{data.numPedidos}</h1>
                        </div>
                    </div>
                    <div className="w3-col l3 m6 s12 w3-padding bs-bbox">
                        <div className="w3-card w3-padding w3-white w3-round-large">
                            <h3>Ventas</h3>
                            <h1 className="w3-text-theme">{Helper.numberUi(data.montoTotal)} Gs.</h1>
                        </div>
                    </div>
                    <div className="w3-col l3 m6 s12 w3-padding bs-bbox">
                        <div className="w3-card w3-padding w3-white w3-round-large">
                            <h3>Planilla clientes</h3>
                            {pathPlanillaClientes ? <a target="_blank" download className="w3-btn w3-block w3-green w3-round" href={CONFIG.URL_BASE + pathPlanillaClientes}>
                                <FontAwesomeIcon icon={faFileExcel} fontSize={18} color={'#fff'} />
                                <span>&nbsp;Descargar</span>
                            </a> : null}
                        </div>
                    </div>
                    <div className="w3-col l3 m6 s12 w3-padding bs-bbox">
                        <div className="w3-card w3-padding w3-white w3-round-large">
                            <h3 style={{marginBottom: '0px'}}>Planilla ventas</h3>
							<KeyboardDatePicker
								cancelLabel="Cancelar"
								clearLabel="Limpiar"
								okLabel="Ok"
								clearable
								id="anho-mes"
								label="Año/Mes"
								format="YYYY-MM"
								value={anhoMes}
								onChange={onChangeAnhoMes}
								views={['year', 'month']}
								KeyboardButtonProps={{
									'aria-label': 'Cambiar año y mes',
								}}
								style={{ marginBottom: 5 }}
							/>
                            {anhoMes ? <a target="_blank" download className="w3-btn w3-block w3-green w3-round" style={{marginTop: '3px'}} href={CONFIG.URL_BASE + pathPlanillaVentasDelMes + moment(anhoMes).format('YYYY') + '/' + moment(anhoMes).format('MM')}>
                                <FontAwesomeIcon icon={faFileExcel} size="md" color={'#fff'} />
                                <span>&nbsp;Descargar</span>
                            </a> : null}
                        </div>
                    </div>
                </div>
                {chartData ? <div className="w3-row">
                    <div className="w3-col l12 w3-padding bs-bbox">
                        <div className="w3-card w3-padding w3-white w3-round-large">
                            <h3>Últimos pedidos</h3>
							{chartData.length > 1 ? (
								<Chart
									chartType="ColumnChart"
									width="100%"
									height="400px"
									data={chartData}
								/>
							) : (
								<h3 className="w3-text-gray">No hay pedidos registrados</h3>
							)}
                        </div>
                    </div>
                </div> : null}
                {ultimosPedidos ? <div className="w3-row">
                    <div className="w3-col l12 w3-padding bs-bbox">
                        <div className="w3-card w3-white w3-round-large" style={{ padding: '16px 16px 60px 16px' }}>
                            <h3>Últimos pedidos</h3>
                            {ultimosPedidos.length > 0 ? <div className="w3-block w3-margin-top">
                                <table className="w3-table w3-border w3-bordered tabla w3-white">
                                    <thead className="w3-border">
                                        <tr>
                                            <th>Id</th>
                                            <th>Cliente</th>
                                            <th>Fecha</th>
                                            <th>Monto total</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="w3-border">
                                        {ultimosPedidos.map((item, index) => <tr key={item.id} className={item.anchorEl ? 'w3-light-gray' : ''}>
                                            <td>{item.id}</td>
                                            <td>{getNombreCompleto(item.usuario)}</td>
                                            <td>{Helper.datetimeToUiFormat(item.recibido)}</td>
                                            <td>{Helper.numberUi(item.montoTotal)} Gs.</td>
                                            <td style={{ width: '120px' }}>
                                                <FontAwesomeIcon icon={faCircle} color={getEstado(item).color} />
                                                <b> {getEstado(item).estado}</b>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div> : (
								<h3 className="w3-text-gray">No hay pedidos registrados</h3>
							)}
                        </div>
                    </div>
                </div> : null}
            </div> : null}
        </div>
    );
}
export default withRouter(Dashboard);